import React from 'react';
import { Gray_Light_950, IconArrowLeftBold32, IconHomeBold32, IconSearchBold32 } from '@croquiscom/zds';
import styled from '@emotion/styled';

import { web_max_width } from '@common/styles';

import { useCartCountQuery } from '../../hooks/useCartCountQuery';
import { BaseNavigation } from '../navigation-bar/BaseNavigation';
import { CartButton } from '../navigation-bar/CartButton';

export const HEADER_HEIGHT = 44;

interface Props {
  main_domain: string;
  show_search_button?: boolean;
  title?: string;
}

export const StoreHomeHeader: React.FC<Props> = ({ main_domain, show_search_button = true, title }) => {
  const { data: cart_count } = useCartCountQuery();

  const renderHomeButton = () => (
    <a href={'/'}>
      <IconHomeBold32 color={Gray_Light_950} />
    </a>
  );

  const renderSearchButton = () => (
    <a href={`/${main_domain}/search`}>
      <IconSearchBold32 color={Gray_Light_950} />
    </a>
  );

  return (
    <StyledBaseNavigation
      center={title}
      left={
        <HeaderLeftButton
          type='button'
          onClick={() => {
            window.history.back();
          }}
        >
          <IconArrowLeftBold32 color={Gray_Light_950} />
        </HeaderLeftButton>
      }
      right={
        <HeaderRightButtons>
          {renderHomeButton()}
          {show_search_button && renderSearchButton()}
          <CartButton href='/cart' count={cart_count} />
        </HeaderRightButtons>
      }
    />
  );
};

const StyledBaseNavigation = styled(BaseNavigation)`
  position: sticky;
  top: 0;
  width: 100%;
  max-width: ${web_max_width}px;
  z-index: 205;
`;

const HeaderLeftButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const HeaderRightButtons = styled.span`
  display: flex;
  justify-content: flex-end;
  & > a {
    margin: 0;
    display: flex;
    box-sizing: content-box;
    padding: 0 8px;
    justify-content: center;
    align-items: center;

    &:last-of-type {
      padding-right: 0;
    }
  }
`;
