import React from 'react';

import { Log } from '@common/log-manager';
import { UxGoodsCardItemForVerticalProductCard, VerticalProductCardWithItem } from '@widgets/product-card';

import { useProductCardEvents } from '../../hooks/useProductCardEvents';

export interface StoreHomeVerticalProductCardProps {
  item: UxGoodsCardItemForVerticalProductCard;
  log?: Partial<Log>;
  use_impression?: boolean;
}

export const StoreHomeVerticalProductCard: React.FC<StoreHomeVerticalProductCardProps> = ({ item, ...props }) => {
  const { log, use_impression } = props;
  const { onClick, onImpression, onLikeClick } = useProductCardEvents();

  const handleProductReveal = (item: UxGoodsCardItemForVerticalProductCard) => {
    if (log != null && use_impression) {
      onImpression(item, log?.object_idx, log);
    }
  };

  return (
    <VerticalProductCardWithItem
      item={item}
      onImpression={handleProductReveal}
      onClick={(item, event) => onClick(event, item, log?.object_idx, log)}
      onLikeClick={(isLiked, item) => onLikeClick(isLiked, item, log?.object_idx, log)}
    />
  );
};
