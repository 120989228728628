import { FC } from 'react';
import { IconShoppingbagSolid32 } from '@croquiscom/zds';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import { Gray_Light_900, Gray_Light_White, Typography } from '@common/styles';
import { LookBookComponentType } from '@domains/storehome-common';

import { ComponentProps } from '../../../types';

export const LookBookCollection: FC<ComponentProps<LookBookComponentType>> = ({ component: lookbook_component }) => {
  const router = useRouter();
  const { title, image_url_list, action_button } = lookbook_component;
  const is_valid_image_count = image_url_list.length === 3;

  const moveToCollectionPage = () => {
    if (action_button) {
      if (action_button.link_url) {
        // 일단 앱 랜딩을 통해 처리하기로 하고 아래의 코드 주석처리(https://croquis.atlassian.net/browse/SELFE-1565). by lucas
        const match = /.+url=(.+)/.exec(action_button.link_url);
        if (match) {
          const collection_detail_url = match[1];
          return collection_detail_url && router.push(collection_detail_url);
        }
      }
    }
  };

  const getRatioImage = (image_url: string) => {
    return (
      <ImageWrap>
        <Dimmed />
        <Image src={image_url} />
      </ImageWrap>
    );
  };

  return (
    <Root onClick={moveToCollectionPage}>
      <Title className={Typography.BODY_18_SEMIBOLD}>{title}</Title>
      <LookBookGrid>
        {is_valid_image_count && (
          <>
            <FirstImageWrap>{getRatioImage(image_url_list[0])}</FirstImageWrap>

            <SecondaryImageWrap>
              {getRatioImage(image_url_list[1])}
              {getRatioImage(image_url_list[2])}
            </SecondaryImageWrap>
          </>
        )}

        {action_button && (
          <ProductMoreBadge>
            <IconShoppingbagSolid32 size={16} color={Gray_Light_White} />
            <span className={Typography.CAPTION_12_SEMIBOLD}>상품 보기</span>
          </ProductMoreBadge>
        )}
      </LookBookGrid>
    </Root>
  );
};

const Root = styled.section`
  position: relative;
  padding: 8px 0 32px;
`;

const Title = styled.h3`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  overflow: hidden;
  white-space: pre;
  color: ${Gray_Light_900};
  min-height: 46px;
  cursor: pointer;
`;

const LookBookGrid = styled.div`
  display: flex;
  position: relative;
  height: calc(min(100vw, 750px) * 0.8);
  background-color: ${Gray_Light_White};
  margin: 0 16px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
`;

const FirstImageWrap = styled.div`
  display: flex;
  position: relative;
  flex: 0 1 66%;
  min-width: 250px;
`;

const SecondaryImageWrap = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 0 1 34%;
  padding: 0 0 0 2px;

  > div {
    min-height: 149px;
  }

  > * + * {
    margin: 2px 0 0;
  }
`;

const ImageWrap = styled.div`
  flex: 1;
`;

const Dimmed = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03));
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProductMoreBadge = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  padding: 0 6px;
  left: 8px;
  bottom: 8px;
  width: 77px;
  height: 29px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 100px;
  cursor: pointer;

  > span {
    margin: 0 0 0 2px;
    color: ${Gray_Light_White};
  }
`;
