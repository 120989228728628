import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconHomeBold32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path stroke='currentColor' strokeWidth={2} d='M12.5 11h6l-5 10h6' />
      <path
        stroke='currentColor'
        strokeLinecap='square'
        strokeWidth={2}
        d='M9 6h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3Z'
      />
    </Svg>
  );
};
export default SvgIconHomeBold32;
