import { FC, useMemo } from 'react';

import { Log } from '@common/log-manager';
import { useInitLikedProducts } from '@widgets/like-product';
import {
  convertProductItemListToUxGoodsCardItemList,
  convertProductListToIdList,
  ShopUxProductCardItem,
  StoreHomeVerticalProductCardList,
  useEnvConfig,
} from '@domains/storehome-common';

import { ComponentProps } from '../../types';

interface UxProductListProps extends ComponentProps<ShopUxProductCardItem[]> {
  log?: Partial<Log>;
  use_impression?: boolean;
}

export const UxProductList: FC<UxProductListProps> = ({ component, log, use_impression }) => {
  const { is_zigzag } = useEnvConfig();
  const products = useMemo(
    () => convertProductItemListToUxGoodsCardItemList({ item_list: component, is_zigzag, show_ranking: false }),
    [component, is_zigzag],
  );
  useInitLikedProducts({
    data: products,
    convert: convertProductListToIdList,
  });

  if (products instanceof Array === false || products.length === 0) {
    return null;
  }

  return <StoreHomeVerticalProductCardList item_list={products} log={log} use_impression={use_impression} />;
};
