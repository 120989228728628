import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconShoppingbagSolid32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M13.75 8.5c0-.796.291-1.339.686-1.69.408-.362.974-.56 1.564-.56.59 0 1.156.198 1.564.56.395.351.686.894.686 1.69V9h-4.5v-.5Zm-1.5.5v-.5c0-1.204.459-2.161 1.19-2.81.716-.638 1.65-.94 2.56-.94.91 0 1.844.302 2.56.94.731.649 1.19 1.606 1.19 2.81V9h3.75L25 25H7L8.5 9h3.75Z'
        clipRule='evenodd'
      />
    </Svg>
  );
};
export default SvgIconShoppingbagSolid32;
