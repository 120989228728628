import { FC } from 'react';
import styled from '@emotion/styled';

import { EmptyWarningBType80Icon } from '@common/icons';
import { Gray_Light_050, Gray_Light_400, Gray_Light_900, Gray_Light_950, Typography } from '@common/styles';

export const ProductEmptyError: FC = () => {
  return (
    <Wrap>
      <EmptyWarningBType80Icon />
      <ErrorMessage className={Typography.BODY_18_BOLD}>상품이 없어요</ErrorMessage>
      <ErrorDescription className={Typography.BODY_15_MEDIUM}>
        스토어에서 상품을 준비 중이에요.
        <br />
        조금만 기다려 주세요!
      </ErrorDescription>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const ErrorMessage = styled.h2`
  margin: 25px 0 0 0;
  color: ${Gray_Light_900};
`;

const ErrorDescription = styled.p`
  margin: 8px 0 0 0;
  color: ${Gray_Light_400};
  white-space: pre;
  text-align: center;
  line-height: 24px;
`;

const RetryButton = styled.button`
  margin: 16px 0 0 0;
  background: ${Gray_Light_950};
  border-radius: 24px;
  width: 130px;
  height: 48px;
  color: ${Gray_Light_050};
`;
