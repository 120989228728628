export const EmptyWarningBType80Icon = ({ width = 80, height = 80, ...props }: JSX.IntrinsicElements['svg']) => (
  <svg width={width} height={height} fill='none' viewBox='0 0 80 80' {...props}>
    <g clipPath='url(#clip0_12753_33583)'>
      <path
        d='M36.4966 16.9935L11.5852 58.801C11.2146 59.4228 11.015 60.132 11.0066 60.8564C10.9983 61.5808 11.1815 62.2945 11.5377 62.9247C11.8938 63.5548 12.4102 64.0789 13.034 64.4435C13.6578 64.8081 14.3668 65.0001 15.0888 65H64.9129C65.6347 64.9999 66.3435 64.8077 66.9672 64.443C67.5908 64.0783 68.1069 63.5542 68.4629 62.9241C68.8189 62.2939 69.0021 61.5804 68.9936 60.8561C68.9852 60.1318 68.7856 59.4227 68.4151 58.801L43.5037 16.9935C43.1415 16.3854 42.6282 15.8819 42.0142 15.5324C41.4001 15.1828 40.7061 14.999 40.0001 14.999C39.2941 14.999 38.6002 15.1828 37.9861 15.5324C37.372 15.8819 36.8588 16.3854 36.4966 16.9935Z'
        fill='url(#paint0_linear_12753_33583)'
      />
      <path
        d='M40.6313 57.4274C42.2897 57.4274 43.6341 56.0783 43.6341 54.414C43.6341 52.7497 42.2897 51.4005 40.6313 51.4005C38.9728 51.4005 37.6284 52.7497 37.6284 54.414C37.6284 56.0783 38.9728 57.4274 40.6313 57.4274Z'
        fill='#666666'
      />
      <path
        d='M43.4491 33.5245C43.387 33.4591 43.3122 33.4071 43.2294 33.3717C43.1466 33.3362 43.0575 33.3181 42.9674 33.3184H38.2965C38.2088 33.3182 38.122 33.3354 38.0409 33.3689C37.9599 33.4024 37.8862 33.4517 37.8242 33.5138C37.7621 33.576 37.7129 33.6498 37.6793 33.7311C37.6457 33.8123 37.6284 33.8994 37.6284 33.9874V34.0161L38.2965 49.4207C38.3038 49.5931 38.3773 49.756 38.5016 49.8753C38.6258 49.9947 38.7912 50.0612 38.9632 50.061H42.2994C42.4715 50.0612 42.637 49.9947 42.7615 49.8754C42.886 49.7561 42.9597 49.5932 42.9674 49.4207L43.6341 34.0161C43.6382 33.9258 43.6239 33.8357 43.5921 33.7512C43.5602 33.6667 43.5116 33.5896 43.4491 33.5245Z'
        fill='#666666'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_12753_33583'
        x1='40.0001'
        y1='88.8637'
        x2='40.0001'
        y2='15'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#BBBBBB' />
        <stop offset='1' stopColor='#E8E8E8' />
      </linearGradient>
      <clipPath id='clip0_12753_33583'>
        <rect width='58' height='50' fill='white' transform='translate(11 15)' />
      </clipPath>
    </defs>
  </svg>
);
