import { FC, useMemo } from 'react';
import { vars } from '@croquiscom-pvt/zds/dist/tokens';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import { Log, useUBL } from '@common/log-manager';
import { Typography } from '@common/styles';
import { useInitLikedProducts } from '@widgets/like-product';
import { HorizontalProductCardListContainer } from '@widgets/product-card';
import {
  convertProductItemListToUxGoodsCardItemList,
  convertProductListToIdList,
  ImageBannerListComponentType,
  useEnvConfig,
  useProductCardEvents,
} from '@domains/storehome-common';

import { ComponentProps } from '../../../types';

interface ImageBannerListCollectionProps extends ComponentProps<ImageBannerListComponentType> {
  log?: Partial<Log>;
}

export const ImageBannerListCollection: FC<ImageBannerListCollectionProps> = ({
  component: image_banner_list_component,
  log,
}) => {
  const router = useRouter();
  const tracker = useUBL();
  const { is_zigzag } = useEnvConfig();
  const { title, image_url, product_list, action_button } = image_banner_list_component;

  const { onClick, onImpression, onLikeClick } = useProductCardEvents();

  const product_card_list = useMemo(
    () =>
      convertProductItemListToUxGoodsCardItemList({
        item_list: product_list,
        is_zigzag,
        show_ranking: false,
      }),
    [is_zigzag, product_list],
  );

  useInitLikedProducts({
    data: product_card_list,
    convert: convertProductListToIdList,
  });

  const moveToCollectionPage = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (action_button) {
      if (action_button.link_url) {
        // 일단 앱 랜딩을 통해 처리하기로 하고 아래의 코드 주석처리(https://croquis.atlassian.net/browse/SELFE-1565). by lucas
        const match = /.+url=(.+)/.exec(action_button.link_url);
        if (match) {
          const collection_detail_url = match[1];
          if (log != null) {
            tracker({
              navigation: '',
              ...log,
              category: 'click',
              object_type: 'button',
              object_id: 'see_more',
            });
          }
          return collection_detail_url && router.push(collection_detail_url);
        }
      }
    }
  };

  return (
    <Root onClick={moveToCollectionPage}>
      <RatioWrap>
        <ImageBannerWrap>
          <Dimmed />
          <Image src={image_url} />
        </ImageBannerWrap>

        <Title className={Typography.HEAD_20_BOLD}>{title}</Title>
      </RatioWrap>
      <CollectionWrap>
        <HorizontalProductCardListContainer
          likeButtonType='thumbnail'
          thumbnailWidth={110}
          items={product_card_list}
          onClick={(item, index, event) => {
            return onClick(event, item, index, log && { ...log, object_idx: index });
          }}
          onLikeClick={(isLiked, item, index) => {
            return onLikeClick(isLiked, item, index, log && { ...log, object_idx: index });
          }}
          // 임프레션 로그의 경우 스토어홈에서는 현재 사용하지 않음
          // 참고: https://www.notion.so/croquis/Web-fe7524ce0c9a4bdd837f254fac57b624?pvs=4#9c99f64ff4884330b988e5cde217e4d3
        />
        {action_button && (
          <MoreButton className={Typography.BODY_15_BOLD} onClick={moveToCollectionPage}>
            {action_button.text}
          </MoreButton>
        )}
      </CollectionWrap>
    </Root>
  );
};

const Root = styled.section`
  position: relative;
  padding: 8px 0 24px;
  cursor: pointer;
`;

const RatioWrap = styled.div`
  position: relative;
  padding: 0 0 80% 0;
  overflow: hidden;
  width: 100%;
  height: 0;
  z-index: -1;
`;

const ImageBannerWrap = styled.a`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Dimmed = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 37.67%, rgba(0, 0, 0, 0.3) 60%);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Title = styled.strong`
  display: block;
  position: absolute;
  width: 100%;
  padding: 0 24px;
  max-height: 48px;
  bottom: calc(min(100vw, 750px) * 0.16 + 16px);
  overflow: hidden;
  white-space: pre;
  color: ${vars.color.white};
`;

const CollectionWrap = styled.div`
  margin: 0 16px;
  padding: 14px 0 0;
  margin-top: calc(min(100vw, 750px) * -0.16);
  border-radius: 12px 12px 0px 0px;
  background-color: ${vars.color.white};
  overflow: hidden;
`;

const MoreButton = styled.button`
  width: 100%;
  height: 48px;
  border: solid 1px ${vars.color.gray200};
  border-radius: 8px;
  margin: 14px 0 0;
  background-color: ${vars.color.white};
  color: ${vars.color.textPrimary};
  cursor: pointer;
`;
