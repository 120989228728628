import { FC } from 'react';
import { IconLongArrowDownRegular32 } from '@croquiscom/zds';
import styled from '@emotion/styled';

import { Gray_Light_150, Gray_Light_600, Typography } from '@common/styles';

import { GetComponentList_shop_ux_component_list_item_list_ShopUxProductCarousel_more_button as MoreButtonProps } from '../../graphql/index_types.2';

type Props = MoreButtonProps & {
  onClick?: () => void;
};

export const MoreButton: FC<Props> = ({ text, onClick }) => {
  return (
    <Root onClick={onClick}>
      <ProductCarouselMoreButton>
        <ArrowIcon size={24} color={Gray_Light_600} />
      </ProductCarouselMoreButton>
      <ProductCarouselMoreText className={Typography.BODY_13_SEMIBOLD}>{text}</ProductCarouselMoreText>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  width: 100%;
  height: 192px;
  cursor: pointer;
`;

const ProductCarouselMoreButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid ${Gray_Light_150};
`;

const ArrowIcon = styled(IconLongArrowDownRegular32)`
  transform: rotate(270deg);
`;

const ProductCarouselMoreText = styled.span`
  display: block;
  margin: 8px 0 0;
  color: ${Gray_Light_600};
`;
