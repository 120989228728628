import { FC, useMemo } from 'react';
import { IconArrowRightLight32 } from '@croquiscom/zds';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import { Log, useUBL } from '@common/log-manager';
import { Gray_Light_400, Gray_Light_900, Typography } from '@common/styles';
import { useInitLikedProducts } from '@widgets/like-product';
import {
  convertProductItemListToUxGoodsCardItemList,
  convertProductListToIdList,
  MoreButton as ProductCarouselMoreButton,
  ProductGroupComponentType,
  StoreHomeVerticalProductCard,
  useEnvConfig,
} from '@domains/storehome-common';

import { ComponentProps } from '../../types';

enum ProductGroupContentsType {
  BEST_PRODUCTS = 'BEST_PRODUCTS',
  ZIGZIN_PRODUCTS = 'ZIGZIN_PRODUCTS',
  COLLECTION = 'COLLECTION',
}

interface ProductGroupProps extends ComponentProps<ProductGroupComponentType> {
  log?: Partial<Log>;
  shop_id: string;
  category_id?: string;
}

export const ProductGroup: FC<ProductGroupProps> = ({
  shop_id,
  category_id,
  component: product_group_component,
  log,
}) => {
  const router = useRouter();
  const { query } = router;
  const tracker = useUBL();
  const { is_zigzag } = useEnvConfig();

  const {
    main_title,
    product_carousel: { component_list, more_button: product_carousel_more_button },
    more_button,
    contents_type,
  } = product_group_component;

  const is_multiline_title = main_title?.text ? main_title.text.split(/\r?\n/).length > 1 : false;

  const product_list = useMemo(
    () =>
      convertProductItemListToUxGoodsCardItemList({
        item_list: component_list,
        is_zigzag,
        show_ranking: false,
      }),
    [component_list, is_zigzag],
  );

  useInitLikedProducts({
    data: product_list,
    convert: convertProductListToIdList,
  });

  const handleMoreButtonClick = () => {
    if (contents_type === ProductGroupContentsType.ZIGZIN_PRODUCTS) {
      tracker({
        category: 'pageview',
        navigation: 'shop_detail',
        navigation_sub: { shop_id },
        object_section: 'zonly',
        object_type: 'button',
        object_id: 'see_more',
      });
      return router.push(`/${query.main_domain}/zigzin-shipping`);
    }
    if (more_button && more_button.link_url) {
      const match = /.+url=(.+)/.exec(more_button.link_url);
      if (match) {
        const link_url = match[1];
        if (ProductGroupContentsType.BEST_PRODUCTS === contents_type) {
          tracker({
            category: 'pageview',
            navigation: category_id ? 'shop_category_detail' : 'shop_detail',
            navigation_sub: { shop_id },
            object_section: 'best',
            object_type: 'button',
            object_id: 'see_more',
          });
        }
        return link_url &&
          [ProductGroupContentsType.COLLECTION, ProductGroupContentsType.BEST_PRODUCTS].includes(
            contents_type as ProductGroupContentsType,
          )
          ? router.push(link_url)
          : window.open(link_url, '_blank');
      }
    }
  };

  const handleCarouselMoreButtonClick = () => {
    if (contents_type === ProductGroupContentsType.ZIGZIN_PRODUCTS) {
      return router.push(`/${query.main_domain}/zigzin-shipping`);
    }

    if (product_carousel_more_button && product_carousel_more_button.link_url) {
      const match = /.+url=(.+)/.exec(product_carousel_more_button.link_url);

      if (match) {
        const link_url = match[1];
        return link_url && contents_type === ProductGroupContentsType.COLLECTION
          ? router.push(link_url)
          : window.open(link_url, '_blank');
      }
    }
  };

  if (!product_group_component) {
    return null;
  }

  return (
    <Wrap className='product-group'>
      <Header css={{ height: is_multiline_title ? 68 : 46 }}>
        <MainTitle
          className={Typography.BODY_18_SEMIBOLD}
          dangerouslySetInnerHTML={{ __html: main_title?.text ?? '' }}
        />
        {more_button && (
          <MoreButton className={Typography.BODY_14_MEDIUM} onClick={() => handleMoreButtonClick()}>
            <span>{more_button.text}</span>
            <IconArrowRightLight32 size={20} color={Gray_Light_400} />
          </MoreButton>
        )}
      </Header>
      <List contents_type={contents_type as ProductGroupContentsType}>
        {product_list.map((item, index) => (
          <ProductCardWrap key={`${item.catalog_product_id || index}`}>
            <StoreHomeVerticalProductCard item={item} log={log && { ...log, object_idx: index }} />
          </ProductCardWrap>
        ))}
        {product_carousel_more_button && (
          <ProductCarouselMoreWrap>
            <ProductCarouselMoreButton
              {...product_carousel_more_button}
              onClick={() => handleCarouselMoreButtonClick()}
            />
          </ProductCarouselMoreWrap>
        )}
      </List>
    </Wrap>
  );
};

const Wrap = styled.section`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  margin: 8px 0 0;
`;

const MainTitle = styled.p`
  color: ${Gray_Light_900};
  overflow: hidden;
  white-space: pre;
`;

const MoreButton = styled.button`
  display: flex;
  align-items: center;
  margin-left: auto;
  color: ${Gray_Light_400};
  padding: 3px 4px;
  cursor: pointer;
`;

const ProductCardWrap = styled.li`
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
`;

const ProductCarouselMoreWrap = styled.li`
  flex-grow: 0;
  flex-shrink: 0;
`;

const List = styled.ul<{ contents_type: ProductGroupContentsType }>`
  display: flex;
  padding: 0 16px ${(props) => (props.contents_type === ProductGroupContentsType.BEST_PRODUCTS ? '24px' : '16px')};
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  ${ProductCardWrap}, ${ProductCarouselMoreWrap} {
    width: 160px;
    height: auto;
  }

  & > * + * {
    margin-left: 2px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
