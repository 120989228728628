import {
  GetComponentList_shop_ux_component_list_item_list as ComponentItem,
  GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem,
  GetSearchedStoreHomeProductList_page_info_ui_item_list as SearchProductListItem,
  GetZigzinShippingComponentList_zigzin_saved_shop_items_ui_item_list as ZigzinShippingComponentItem,
  ShopUxBrowsingType,
  ShopUxComponentType,
  ShopUxSalesStatus,
  ShopUxShippingFeeType,
  UxComponentType,
} from '../graphql/index_types.2';
import {
  ImageBannerListComponentType,
  LookBookComponentType,
  ProductComponentType,
  ProductGroupComponentType,
  TextAndSortingComponentType,
  UxGoodsCardComponentType,
} from '../types';

export function mergePages<T>(pages: { item_list: T[] }[]): T[] {
  return pages.flatMap((page) => page.item_list);
}

type RawComponentItem = ComponentItem | ZigzinShippingComponentItem | SearchProductListItem;

const COMPONENT_MAPPING_TABLE: Partial<Record<RawComponentItem['type'], ComponentItem['type'] | undefined>> = {
  [ShopUxComponentType.TEXT_AND_SORT]: ShopUxComponentType.TEXT_AND_SORT,
  [UxComponentType.UX_TEXT_AND_SORTING]: ShopUxComponentType.TEXT_AND_SORT,
  [UxComponentType.UX_CHECK_BUTTON_AND_SORTING]: ShopUxComponentType.TEXT_AND_SORT,
  [ShopUxComponentType.PRODUCT]: ShopUxComponentType.PRODUCT,
  [UxComponentType.UX_GOODS_CARD_ITEM]: ShopUxComponentType.PRODUCT,
  [ShopUxComponentType.PRODUCT_GROUP]: ShopUxComponentType.PRODUCT_GROUP,
  [ShopUxComponentType.IMAGE_BANNER_AND_LIST]: ShopUxComponentType.IMAGE_BANNER_AND_LIST,
  [ShopUxComponentType.LOOKBOOK]: ShopUxComponentType.LOOKBOOK,
};

export function normalizeComponent(component: RawComponentItem | null): ComponentItem | null {
  if (component == null) return null;
  const mapped_type = COMPONENT_MAPPING_TABLE[component.type];
  if (mapped_type == null) return null;
  if (component.type === UxComponentType.UX_GOODS_CARD_ITEM) {
    const old_product = component as UxGoodsCardComponentType;
    const new_product: GetComponentList_shop_ux_component_list_item_list_ShopUxProductCardItem = {
      ...old_product,
      type: ShopUxComponentType.PRODUCT,
      product: {
        catalog_product_id: old_product.catalog_product_id,
        discount_rate: old_product.discount_rate,
        image_url: old_product.image_url ?? '',
        name: old_product.title ?? '',
        payment_type: null,
        price: old_product.price ?? -1,
        sales_status: ShopUxSalesStatus.ON_SALE,
        shipping_fee_type: old_product.free_shipping
          ? ShopUxShippingFeeType.FREE_SHIPPING
          : ShopUxShippingFeeType.SHOP_POLICY,
        shop_id: Number(old_product.shop_id),
        shop_product_no: old_product.shop_product_no,
        url: old_product.product_url ?? '',
        webp_image_url: old_product.webp_image_url,
      },
      is_display_not_zpay: !old_product.is_zonly,
      review_score: null,
      review_count: old_product.review_count ? String(old_product.review_count) : null,
      browsing_type: old_product.browsing_type as unknown as ShopUxBrowsingType,
      column_count: null,
      managed_category_list: filterNonNull(old_product.managed_category_list),
      // 서버 스키마 타입 이슈로 is_plp_v2 로직을 유지
      is_plp_v2: old_product.is_plp_v2 ?? false,
    };
    return new_product;
  }
  return { ...component, type: mapped_type };
}

function filterNonNull<T>(array: (T | null | undefined)[] | null): T[] {
  if (array === null) {
    return [];
  }
  return array.filter((item): item is T => item !== null && item !== undefined);
}
const COMPONENT_ALLOW_TABLE = {
  [ShopUxComponentType.TEXT_AND_SORT]: true,
  [ShopUxComponentType.PRODUCT]: true,
  [ShopUxComponentType.PRODUCT_GROUP]: true,
  [ShopUxComponentType.IMAGE_BANNER_AND_LIST]: true,
  [ShopUxComponentType.LOOKBOOK]: true,
} as const;

type AllowedComponentType = keyof typeof COMPONENT_ALLOW_TABLE;

export interface GrouppedComponentListMap {
  [ShopUxComponentType.TEXT_AND_SORT]?: TextAndSortingComponentType[];
  [ShopUxComponentType.PRODUCT]?: ProductComponentType[];
  [ShopUxComponentType.PRODUCT_GROUP]?: ProductGroupComponentType[];
  [ShopUxComponentType.IMAGE_BANNER_AND_LIST]?: ImageBannerListComponentType[];
  [ShopUxComponentType.LOOKBOOK]?: LookBookComponentType[];
}

export function groupComponents(components: (ComponentItem | null)[]): GrouppedComponentListMap {
  const mapped: GrouppedComponentListMap = {};
  components.forEach((component) => {
    if (component == null) return;
    const { type } = component;
    if (!(type in COMPONENT_ALLOW_TABLE)) return;
    const refined_type = type as AllowedComponentType;
    const list = mapped[refined_type];
    if (list == null) {
      mapped[refined_type] = [component as any];
    } else {
      list.push(component as any);
    }
  });
  return mapped;
}
