import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconLongArrowDownRegular32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path stroke='currentColor' strokeWidth={1.5} d='m23 17.2-7 7-7-7' />
      <path stroke='currentColor' strokeLinejoin='round' strokeWidth={1.5} d='M16 24V7' />
    </Svg>
  );
};
export default SvgIconLongArrowDownRegular32;
