import { FC, useCallback } from 'react';
import styled from '@emotion/styled';

import { useIntersect } from '@common/hooks';
import { Log } from '@common/log-manager';
import { Gray_Light_White } from '@common/styles';
import { DefaultError, GrouppedComponentListMap, ProductEmptyError } from '@domains/storehome-common';
import {
  ImageBannerListComponentType,
  LoadingDots,
  LookBookComponentType,
  ProductGroupComponentType,
  TextAndSortingComponentType,
} from '@domains/storehome-common';

import { ImageBannerListCollection } from './collection/image-banner-list';
import { LookBookCollection } from './collection/lookbook';
import { Filter } from './filter';
import { ProductGroup } from './product-group';
import { UxProductList } from './product-list';

const ERROR_STRING_FETCH_SERVER_ERROR = '잠시 후에 다시 시도해주세요.\n문제가 지속되면 고객센터로 문의해주세요.';

const getUBLObjectSectionByContentType = (type: string) => {
  switch (type) {
    case 'BEST_PRODUCTS':
      return 'best';
    case 'ZIGZIN_PRODUCTS':
      return 'zonly';
    case 'COLLECTION':
      return 'shop_collection_display';
    default:
      return 'all';
  }
};

interface Props {
  type: 'store' | 'category' | 'zigzin-shipping';
  params: { shop_id: string; category_id?: string | null };
  components: GrouppedComponentListMap;
  error?: unknown;
  is_fetching?: boolean;
  has_next_page?: boolean;
  onFetchNextPage: () => void;
  onParamsChange: (params: { check_button_item_ids: string[]; sorting_item_id: string }) => void;
}

export const ComponentList: FC<Props> = ({
  type,
  params,
  components,
  error,
  is_fetching,
  has_next_page,
  onFetchNextPage,
  onParamsChange,
}) => {
  const page_log: Partial<Log> | undefined =
    type === 'zigzin-shipping'
      ? undefined
      : {
          navigation: type === 'store' ? 'shop_detail' : 'shop_category_detail',
          navigation_sub: { shop_id: params.shop_id },
          data: type === 'store' ? undefined : { category_id: params.category_id },
        };

  const intersectionObserverCallback = useCallback(
    (entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting && has_next_page) {
        onFetchNextPage();
      }
    },
    [has_next_page, onFetchNextPage],
  );

  const { targetRef } = useIntersect(intersectionObserverCallback);

  if (error || !components) {
    return (
      <ErrorWrap>
        <DefaultError message={ERROR_STRING_FETCH_SERVER_ERROR} />
      </ErrorWrap>
    );
  }

  const {
    PRODUCT_GROUP: product_group_data,
    TEXT_AND_SORT: text_and_sort_data,
    PRODUCT: product_data,
    IMAGE_BANNER_AND_LIST: image_banner_and_list_data,
    LOOKBOOK: lookbook_data,
  } = components;

  if (!product_group_data?.length && !text_and_sort_data?.length && !product_data?.length) {
    return (
      <ErrorWrap>
        <ProductEmptyError />
      </ErrorWrap>
    );
  }

  return (
    <ComponentListWrap className='component-list'>
      {product_group_data &&
        product_group_data.map((product_group, index) => (
          <ProductGroup
            key={`${params.shop_id}_${params.category_id}_${index}`}
            shop_id={params.shop_id}
            category_id={params.category_id || ''}
            component={product_group as ProductGroupComponentType}
            log={
              page_log && {
                ...page_log,
                object_section: getUBLObjectSectionByContentType(
                  (product_group as ProductGroupComponentType).contents_type,
                ),
              }
            }
          />
        ))}

      {image_banner_and_list_data &&
        image_banner_and_list_data.map((collection, index) => (
          <ImageBannerListCollection
            key={`${params.shop_id}_${collection.type}_${index}`}
            component={collection as ImageBannerListComponentType}
            log={
              page_log && {
                ...page_log,
                object_section: 'shop_collection_display',
              }
            }
          />
        ))}

      {lookbook_data && <LookBookCollection component={lookbook_data[0] as LookBookComponentType} />}

      {text_and_sort_data && (
        <Filter component={text_and_sort_data[0] as TextAndSortingComponentType} onChangeFilter={onParamsChange} />
      )}

      {product_data && product_data.length ? (
        <UxProductList
          component={product_data}
          log={
            page_log && {
              ...page_log,
              object_section: 'all',
            }
          }
        />
      ) : (
        <ErrorWrap>
          <ProductEmptyError />
        </ErrorWrap>
      )}

      {has_next_page && (
        <LoadingWrap ref={targetRef}>
          <LoadingDots />
        </LoadingWrap>
      )}
    </ComponentListWrap>
  );
};

const ComponentListWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${Gray_Light_White};
  z-index: 10;
`;

const LoadingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

const ErrorWrap = styled.div`
  position: relative;
  flex: 1;
  height: 1px;
  min-height: 400px;
  background-color: ${Gray_Light_White};
`;
