import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconSearchBold32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path stroke='currentColor' strokeWidth={2} d='M14 7A7 7 0 1 0 14 21 7 7 0 1 0 14 7z' />
      <path stroke='currentColor' strokeWidth={2} d='m19 19 6 6' />
    </Svg>
  );
};
export default SvgIconSearchBold32;
