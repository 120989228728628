import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconArrowRightLight32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path stroke='currentColor' d='m12 7 9 9-9 9' />
    </Svg>
  );
};
export default SvgIconArrowRightLight32;
